<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="1200px"
    @click:outside="onClose"
  >
    <template #activator="{ on: dialog }">
      <v-btn
        color="secondary"
        block
        @click="onShowDialog"
        v-on="{ ...dialog }"
        data-test="activateButton"
      >
        Add New Design File Package
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-5">
        <v-toolbar color="primary" dark>
          <v-toolbar-title data-test="dfpToolbar"
            >Add New Design File Package</v-toolbar-title
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <small class="red--text">* indicates required field</small>
          </v-row>
          <v-form lazy-validation ref="form">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Version *"
                  name="version"
                  type="text"
                  prepend-icon="mdi-qrcode"
                  autocomplete="off"
                  autofocus
                  v-model.lazy="version"
                  :rules="rules.requireCheck"
                  data-test="version"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-5">
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  prepend-icon="mdi-card-text"
                  v-model.lazy="notes"
                  rows="1"
                  auto-grow
                  data-test="notes"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Pressure Plate"
                  v-model="pressurePlate"
                  data-test="pressurePlate"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Pressure Plate Assembly"
                  v-model="pressurePlateAssembly"
                  data-test="pressurePlateAssembly"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Moving Plate"
                  v-model="movingPlate"
                  data-test="movingPlate"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Moving Plate Assembly"
                  v-model="movingPlateAssembly"
                  data-test="movingPlateAssembly"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row dense class="mb-5">
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Probe Plate"
                  v-model="probePlate"
                  data-test="probePlate"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Probe Plate Assembly"
                  v-model="probePlateAssembly"
                  data-test="probePlateAssembly"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Cartridge Assembly"
                  v-model="cartridgeAssembly"
                  data-test="cartridgeAssembly"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Fixture Assembly"
                  v-model="fixtureAssembly"
                  data-test="fixtureAssembly"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-file-input
                  show-size
                  counter
                  label="Render File"
                  v-model="renderFile"
                  data-test="renderFile"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip small label color="primary">
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" md="6" class="d-flex align-end">
                <v-select
                  dense
                  prepend-icon="mdi-cog"
                  :items="renderList"
                  label="Render Settings"
                  item-text="description"
                  item-value="pk"
                  v-model="renderSettings"
                  data-test="render_setting"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider />
        <v-row class="ma-2">
          <v-btn color="warning" @click="onClose" data-test="closeButton"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSave" data-test="saveButton"
            >Save</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DesignFilePackageModal",
  props: {
    renderList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      version: null,
      notes: null,
      pressurePlate: null,
      pressurePlateAssembly: null,
      movingPlate: null,
      movingPlateAssembly: null,
      probePlate: null,
      probePlateAssembly: null,
      cartridgeAssembly: null,
      fixtureAssembly: null,
      renderFile: null,
      renderSettings: null,
      rules: {
        requireCheck: [(v) => !!v || this.override || "Input is required"],
      },
    };
  },
  methods: {
    onShowDialog() {
      this.designFile = null;
    },
    onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const actionPayload = {
        version: this.version,
        notes: this.notes,
        pressurePlate: this.pressurePlate,
        pressurePlateAssembly: this.pressurePlateAssembly,
        movingPlate: this.movingPlate,
        movingPlateAssembly: this.movingPlateAssembly,
        probePlate: this.probePlate,
        probePlateAssembly: this.probePlateAssembly,
        cartridgeAssembly: this.cartridgeAssembly,
        fixtureAssembly: this.fixtureAssembly,
        renderFile: this.renderFile,
        renderSettings: this.renderSettings,
      };
      this.$emit("saveDesignFile", actionPayload);
      this.onClose();
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
